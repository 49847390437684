import useWindowDimensions from './functions/useWindowDimensions';
import confetti from "./resources/confetti.jpg";
import logo from "./resources/blocklabel_logo5.png";
import React, { useState } from "react";
import firebaseApp from './Fire.js'
import { getDatabase } from "firebase/database"
import { ref, push, set } from "firebase/database"

function App() {
  const { height, width } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const db = getDatabase(firebaseApp)

  const app = {
    display: 'flex',
    flex: 1,
    minHeight: height,
    width: width,
    flexDirection: 'column'
  }
  
  const container = {
    minHeight: height - 60,
    backgroundImage: `url(${confetti})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }

  const title = {
    color: 'white',
    fontSize: width > 800 ? '60px' : '52px',
    fontFamily: 'Lexend',
    textAlign: 'center',
    letterSpacing: '-0.5px',
    fontWeight: '600'
  }

  const stayTuned = {
    color: '#fff', 
    fontFamily: 'Lexend', 
    fontSize: 20, 
    letterSpacing: 2, 
    marginTop: 20,
    fontWeight: '500'
  }

  const navbar = {
    backgroundColor: 'white',
    width: '100%',
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  }

  const navbarBlocklabel = {
    marginLeft: 10, 
    fontSize: 28, 
    fontFamily: "Lexend", 
    color: '#303030',
    letterSpacing: '-1px',
    fontWeight: '500'
  }

  const inputContainer = {
    width: 400, 
    marginTop: 25, 
    marginBottom: 10, 
    border: 'none', 
    height: 40,  
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)', 
    borderRadius: 20, 
    display: 'flex', 
    flexDirection: 'row' ,
    backgroundColor: 'white',
    maxWidth: '85%'
  }

  const input = { 
    fontFamily: 'Lexend', 
    height: '100%', 
    borderTopLeftRadius: 20, 
    borderBottomLeftRadius: 20, 
    border: 'none', 
    padding: 0,
    paddingLeft: 20, 
    paddingRight: 20, 
    width: '75%',
    fontSize: 13.33,
    fontWeight: '400'
  }
  const buttonContainer = {
    paddingLeft: 20, 
    paddingRight: 20,
    height: '100%', 
    border: 'none', 
    borderTopRightRadius: 20, 
    borderBottomRightRadius: 20, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundColor: "#C51D79"
  }


  const submitText = {
    color: 'white', 
    fontFamily: 'Lexend', 
    fontSize: 13.33,
    fontWeight: '500'
  }


  const submitEmail = () => {
    if (email) {
      push(ref(db, 'emails'), {
        email: email
      });
    }
    setEmail("");
  }




  return (
    <div style={app}>
      <div style={navbar}>
        <img width={'40px'} style={{ marginLeft: '3%', marginBottom: 5}} src={logo} />
        <text style={navbarBlocklabel}>Blocklabel</text>
      </div>

      <div style={container}>
        <text style={title}>A Fan-Led Record Label<br/> Powered by Web3</text>
        <text style={stayTuned}>Stay Tuned</text>

        <div style={inputContainer}>
         
          <input value={email} onChange={e => setEmail(e.target.value)} placeholder='Enter email' style={input}/>
          <div onClick={submitEmail} style={buttonContainer}>
            <text style={submitText}>Submit</text>
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;
